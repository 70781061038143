@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Poppins, sans-serif;
}

@keyframes kenburnsOut {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes kenburnsIn {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fromLeft {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fromTop {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}